<!--项目看板-->
<template>

  <el-card class="box-card" style="margin-bottom: 20px;">
    <div slot="header" class="clearfix">
      <span style="font-weight: bold;">
        <el-row :gutter="20" style="padding:0">
          <el-col :span="22">
            <i class="el-icon-box" style="color:#67C23A"></i>
            {{projectData.projectCode}}{{projectData.markCode}}|{{projectData.projectName}}
          </el-col>
          <el-col :span="2" style="padding:0">
            <router-link :to="{name: 'projectInfo', query: {proId:projectData.id}}" v-if="this.allowRoute">
              <el-link effect="plain"><i class="el-icon-view"></i> 项目详情</el-link></router-link>
          </el-col>
        </el-row>
      </span>
    </div>
    <div class="text item">
      <el-form ref="'projectFormRef'+projectData.id" :model="projectData" label-width="80px" size="mini">
        <el-row style="padding: 0;">
          <el-col :span="12">
            <el-row :gutter="20" style="padding-bottom:0px;">
              <el-col :span="12">
                <el-form-item label="所属销售">{{ projectData.saleName|cmpFilter}}</el-form-item>
                <el-form-item label="项目所属">{{ projectData.cmpName }}</el-form-item>
                <el-row style="padding:0px">
                  <el-col :span="12">
                    <el-form-item label="项目年份">{{projectData.projectYear }}年</el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="状态">
                      <span v-if="projectData.isFinish" style="color:#67C23A">已结束</span>
                      <span v-else style="color:#E6A23C">未结束</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-form-item label="最终客户">{{projectData.cusName }}</el-form-item>
                <el-form-item label="客户代理">{{projectData.cusProxyCmp}}</el-form-item>
                <el-form-item label="客户资料"><file-view :fileList="this.customer.addFile"></file-view></el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="padding-bottom:0px;">
              <el-col :span="12"><el-form-item label="创建时间">{{projectData.createDate}}</el-form-item></el-col>
              <el-col :span="12"><el-form-item label="项目总额">
                  <el-tooltip content="该项目下的所有合同总额" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF"></i></el-tooltip>
                  {{ projectData.inContractRmb|numFilter }}<span
                    style="color:#909399;font-size:10px">&nbsp;&nbsp;{{projectData.inContractRmb|nullNumFilter}}</span>
                </el-form-item></el-col>
            </el-row>
          </el-col>
          <el-col :span="12">
            <div style="position: absolute; left:49%;top:20px;width:14px;font-size: 14px;color: #409EFF;">｜资金情况｜</div>
            <el-row style="padding-bottom:0px;" :gutter="0">

              <el-col :span="6">
                <el-form-item label="合同收款">
                  <el-tooltip :content="projectData.inRmb|nullNumFilter" placement="top" effect="light">
                    <i class="el-icon-notebook-2" style="color:#67C23A"> {{ projectData.inRmb|numFilter }}</i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="合同支出">
                  <el-tooltip :content="projectData.outRmb|nullNumFilter" placement="top" effect="light">
                    <i class="el-icon-notebook-2" style="color:#F56C6C"> {{ projectData.outRmb|numFilter }}</i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="其它费用">
                  <el-tooltip :content="projectData.outOtherRmb|nullNumFilter" placement="top" effect="light">
                    <i class="el-icon-wallet" style="color:#F56C6C"> {{ projectData.outOtherRmb|numFilter }}</i>
                  </el-tooltip>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="padding-bottom:0px;" :gutter="0">
              <el-col :span="10"><el-form-item label="收款比率">{{projectData.inRmb|numFilter}}/{{projectData.inContractRmb|numFilter}}</el-form-item>
              </el-col>
              <el-col :span="14" style="padding-right: 30px;">
                <el-form-item label="收款占比">
                  <el-progress :text-inside="true" :stroke-width="24" text-color="#fff"
                    :percentage="countPercentage(projectData.inRmb,projectData.inContractRmb)" :color="colors"></el-progress>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="padding-bottom:0px;">
              <el-form-item label="项目利润">
                <el-tooltip :content="(projectData.inContractRmb-projectData.outRmb-projectData.outOtherRmb)|nullNumFilter" placement="top" effect="light">
                  <span style="color:#67C23A">
                    {{(projectData.inContractRmb-projectData.outRmb-projectData.outOtherRmb)|numFilter}}
                  </span>
                </el-tooltip>
                ＝
                <el-tooltip content="该项目下的所有合同总额" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF"></i></el-tooltip>
                项目总额:{{ projectData.inContractRmb|numFilter}}
                －
                合同支出:{{ projectData.outRmb|numFilter}}
                －
                其它费用:{{ projectData.outOtherRmb|numFilter}}

              </el-form-item>
              <el-form-item label="当前利润">
                <el-tooltip :content="(projectData.inRmb-projectData.outRmb-projectData.outOtherRmb)|nullNumFilter" placement="top" effect="light">
                  <span style="color:#67C23A">{{(projectData.inRmb-projectData.outRmb-projectData.outOtherRmb)|numFilter}}</span>
                </el-tooltip>
                ＝
                <el-tooltip content="该项目下的所有合同收款" placement="top" effect="light"><i class="el-icon-info" style="color:#409EFF"></i></el-tooltip>
                合同收款:{{ projectData.inRmb|numFilter }}
                －
                合同支出:{{ projectData.outRmb|numFilter}}
                －
                其它费用:{{ projectData.outOtherRmb|numFilter }}
              </el-form-item>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-card>
</template>
<script> 
import { getInfo } from '@/api/wbkj/Customer'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import FileView from '@/components/utils/FileView.vue';

export default {

  components: { FileView },
  props: ["projectData", "allowRoute"],
  data () {
    return {
      customer: {},
      colors: [
        { color: '#f56c6c', percentage: 20.0 },
        { color: '#e6a23c', percentage: 40.0 },
        { color: '#5cb87a', percentage: 60.0 },
        { color: '#1989fa', percentage: 80.0 },
        { color: '#6f7ad3', percentage: 100.0 },
      ],
    }
  },
  methods: {},
  watch: {
    projectData: function () {
      getInfo({
        id: this.projectData.cusId
      }).then(response => {
        if (response.data.success) {
          this.customer = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    }
  },
  mounted () {
    if (this.projectData.cusId > 0) {
      getInfo({
        id: this.projectData.cusId
      }).then(response => {
        if (response.data.success) {
          this.customer = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    }


  },

}
</script>